import { getServerSideClients } from '@dx-ui/framework-react-query';
import { getQueryProviderProps } from '@dx-ui/framework-react-query-env-vars';
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { CustomHead } from '../../../components/head';
import type { StandardSchema } from '../../../components/head/page-schemas';
import HotelLayout from '../../../components/layout/HotelLayout';
import { serverSideGetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import {
  mapHomePageDataToComponentProps,
  renderComponents,
} from '../../../helpers/componentMapping/componentMapper';
import { GLOBAL_NAMESPACES, HotelPage, FEATURE } from '../../../helpers/constants';
import { isRenovatedProperty, isFeatureToggleEnabled } from '../../../helpers/utils/server/toggles';
import { useStaticUrls } from '../../../hooks/useStaticUrls';
import { registration } from '../../../mocks/UtilityRailData.mock';
import nextI18nextConfig from '../../../next-i18next.config';
import { getOnSiteRestaurants } from '../../../components/tabbed-overview/dataMappers';
import {
  extractTraitsFromComponentProps,
  shouldShowJapaneseDiningComponent,
} from '../../../helpers/utils/server/conductrics';
import { getDehydratedQueryState } from '../../../helpers/utils/server/getDehydratedQueryState';
import { serverSideLayoutAndRedirects } from '../../../helpers/utils/server/redirect';
import { shouldReturnQueryDataFromGraphQLError } from '../../../helpers/utils/server/shouldReturnQueryDataFromGraphQLError';
import { getLayoutData } from '../../../helpers/utils/server/getLayoutData';
import {
  getBrandSvg,
  getCtyhocnFromSlug,
  getHotelsRouteParams,
} from '../../../helpers/utils/client';

import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import { logError } from '@dx-ui/framework-logger';

const pageName = HotelPage.HOME;
const namespaces = [
  ...GLOBAL_NAMESPACES,
  'close-button',
  'dining',
  'dx-image-carousel',
  'golf',
  'honors',
  'hotel-description',
  'hotel-location',
  'rooms',
  'spa',
  'vouchers',
  'gallery-grid',
  'dx-dialog-with-content',
  'osc-video-player',
  pageName,
];

export default function Page({
  componentsToRender,
  layout,
  pageSchema,
  traits,
  isRecentlyRenovated,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const router = useRouter();
  const { ctyhocn } = getHotelsRouteParams(router);
  const { baseAppUrl } = useStaticUrls();
  const standardSchema: StandardSchema = {
    ...pageSchema,
    url: baseAppUrl,
  };

  const render = renderComponents(componentsToRender);

  if (render.length === 0) {
    logError('RENDER_COMPONENTS_FUNC', ctyhocn, 'No components returned to render on home page.');
  }

  return (
    <HotelLayout
      layout={layout}
      page={pageName}
      ctyhocn={ctyhocn}
      traits={traits}
      isRecentlyRenovated={isRecentlyRenovated}
    >
      <CustomHead
        brandLogoImageSrc={getBrandSvg(layout?.headerData.brandCode)}
        schema={standardSchema}
        metaDescription={layout?.seoMetaData.desc}
        metaKeywords={layout?.seoMetaData.keywords}
        page={pageName}
        pageTitle={layout?.seoMetaData.title}
        brandCode={layout?.headerData.brandCode}
        shouldUseDefaultBrandFavicon={layout?.headerData?.suppressLogo}
      />
      {...render}
    </HotelLayout>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { res, params, locale } = context;
  const envVars = getQueryProviderProps();
  const ctyhocn = getCtyhocnFromSlug(params?.hotelSlug);
  const { queryClient } = getServerSideClients({
    customHeaders: { 'dx-trusted-app': 'true' },
    ...envVars,
    appName: 'dx-property-ui',
    response: res,
  });

  const {
    componentOrder,
    globalConfig,
    layoutResult,
    mainNavLinks,
    resEnabled,
    notFound,
    oneLinkProps,
    originalLocale,
    featureToggles,
    featureConfigs,
    isBrandRefresh,
  } = await serverSideLayoutAndRedirects({
    queryClient,
    ctyhocn,
    pageName,
    hotelSlug: params?.hotelSlug as string,
    context,
  }).catch((e) => {
    console.log('Error in serverSideLayoutAndRedirects', e); // eslint-disable-line no-console

    return {
      componentOrder: null,
      globalConfig: null,
      layoutResult: null,
      mainNavLinks: null,
      resEnabled: null,
      notFound: true,
      oneLinkProps: null,
      originalLocale: null,
      featureToggles: null,
      featureConfigs: null,
      isBrandRefresh: false,
    };
  });

  if (notFound || !globalConfig) {
    return { notFound: true };
  }

  const homeResult: GetHotelHomePageQuery = await serverSideGetHotelHomePageQuery(queryClient, {
    ctyhocn,
  }).catch((e) => {
    console.log('Error in serverSideGetHotelHomePageQuery', e); // eslint-disable-line no-console
    if (shouldReturnQueryDataFromGraphQLError(e)) {
      return e.data;
    }

    return null;
  });

  if (!homeResult) {
    return { notFound: true };
  }

  const layout = getLayoutData({
    ctyhocn,
    layoutResult,
    resEnabled,
    mainNavLinks,
    locale: originalLocale || locale,
  });

  const brandCode = layoutResult?.hotelPageTemplate?.hotel?.brandCode || '';

  const pageSchema: Omit<StandardSchema, 'url'> = {
    address: layout?.address,
    amenityFeature: homeResult?.hotel?.amenities?.map((amenity) => amenity.name),
    checkinTime: registration.checkinTimeFmt,
    checkoutTime: registration.checkoutTimeFmt,
    coordinates: layout?.coordinates,
    image: layout?.image || '',
    openingHours: 'Mo, Tu, We, Th, Fr, Sa, Su',
    schemaType: 'Hotel',
    description: layout?.seoMetaData.desc,
    name: layout?.hotelName,
    telephone: layout?.phone || '',
  };

  const isStayTourVideoEnabled = isFeatureToggleEnabled({
    featureToggleName: FEATURE.TOGGLE.OHWPR_3402_Stay_Tour_Video,
    data: featureToggles,
  });

  const isEmailDisplayedEnabled = isFeatureToggleEnabled({
    featureToggleName: FEATURE.TOGGLE.OHWPR_4205_PIM_CMS_EMAIL,
    data: featureToggles,
  });

  const isRecentlyRenovated = isRenovatedProperty(ctyhocn, featureConfigs);
  const showJapaneseDiningComponent = shouldShowJapaneseDiningComponent(context);
  const onSiteRestaurants = getOnSiteRestaurants(homeResult.hotel?.restaurantOverview?.restaurants);

  const componentsToRender = mapHomePageDataToComponentProps(componentOrder ?? [], {
    ctyhocn,
    globalConfig,
    homepageData: homeResult.hotel,
    isEmailDisplayedEnabled,
    isStayTourVideoEnabled,
    isRecentlyRenovated,
    layout,
    showJapaneseDiningComponent,
    showMeetingsSimplified: !!layoutResult?.hotelPageTemplate?.hotel?.showMeetingsSimplified,
    onSiteRestaurants,
  });

  const traits = extractTraitsFromComponentProps(componentsToRender);

  return {
    props: {
      componentsToRender,
      dehydratedQueryState: getDehydratedQueryState(queryClient),
      globalConfig,
      isBrandRefresh,
      layout,
      themeColors: layout.theme,
      isRecentlyRenovated,
      traits,
      pageSchema,
      propCode: layoutResult?.hotelPageTemplate?.hotel?.propCode,
      ...(await serverSideTranslations('en', namespaces, nextI18nextConfig)),
      ...oneLinkProps,
      metrics: {
        trackingData: {
          pageData: {
            brandCode,
            brandName: layout?.headerData?.brandName,
            pageName,
            ctyhocn,
            template: globalConfig?.template,
            country: layout?.country || '',
          },
        },
      },
    },
  };
}
