import { DO_NOT_RENDER, HotelPage } from '../../helpers/constants';
import { convertCmsImageRatiosToMap } from '../../helpers/utils/server/convertCmsImageRatiosToMap';
import { findNavLink } from '../../helpers/utils/server/findNavLink';

import type { CarouselImages } from '@dx-ui/osc-carousel';
import type { SingleAmenityCollageProps } from './SingleAmenityCollage';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';

const mapCmsImagesToSingleAmenityCollageImages = (
  images:
    | NonNullable<NonNullable<AvailableHomePageData['homepageData']>['golf']>['imageRatios']
    | NonNullable<NonNullable<AvailableHomePageData['homepageData']>['spa']>['imageRatios']
) => {
  const [primaryImage, secondaryImage] = images;
  const { oneByOne: oneByOnePrimaryImage, threeByTwo: threeByTwoPrimaryImage } =
    convertCmsImageRatiosToMap(primaryImage?.ratios || []);
  const { sixteenByNine: sixteenByNineSecondaryImage, threeByTwo: threeByTwoSecondaryImage } =
    convertCmsImageRatiosToMap(secondaryImage?.ratios || []);
  return {
    primary: {
      altText: primaryImage?.altText,
      caption: primaryImage?.caption,
      oneByOne: oneByOnePrimaryImage,
      threeByTwo: threeByTwoPrimaryImage,
    },
    secondary: {
      altText: secondaryImage?.altText,
      caption: secondaryImage?.caption,
      sixteenByNine: sixteenByNineSecondaryImage,
      threeByTwo: threeByTwoSecondaryImage,
    },
  };
};

const mapCmsImagesToSingleAmenityCarouselImages = (
  primaryImage: ReturnType<typeof mapCmsImagesToSingleAmenityCollageImages>['primary'],
  secondaryImage: ReturnType<typeof mapCmsImagesToSingleAmenityCollageImages>['secondary']
): CarouselImages[] => {
  return [
    {
      url: primaryImage.threeByTwo,
      alt: primaryImage.altText || '',
      ...(primaryImage.caption && {
        captionData: {
          caption: primaryImage.caption,
        },
      }),
    },
    {
      url: secondaryImage.threeByTwo,
      alt: secondaryImage.altText || '',
      ...(secondaryImage.caption && {
        captionData: {
          caption: secondaryImage.caption,
        },
      }),
    },
  ];
};

const createSingleAmenityCollage = (
  brandCode: string,
  description: string,
  headline: 'spa' | 'golf',
  images:
    | NonNullable<
        NonNullable<NonNullable<AvailableHomePageData['homepageData']>['golf']>['imageRatios']
      >
    | NonNullable<
        NonNullable<NonNullable<AvailableHomePageData['homepageData']>['spa']>['imageRatios']
      >,
  navLink: string
): SingleAmenityCollageProps => {
  const { primary, secondary } = mapCmsImagesToSingleAmenityCollageImages(images);
  const carouselImages = mapCmsImagesToSingleAmenityCarouselImages(primary, secondary);

  return {
    carouselImages,
    ctaUrl: navLink,
    description,
    headline,
    primaryImage: {
      imageUrl: primary.oneByOne,
      altText: primary.altText || '',
      ...(primary.caption && {
        captionData: {
          caption: primary.caption,
        },
      }),
    },
    secondaryImage: {
      imageUrl: secondary.sixteenByNine,
      altText: secondary.altText || '',
      ...(secondary.caption && {
        captionData: {
          caption: secondary.caption,
        },
      }),
    },
  };
};

export const mapHomePageDataToSingleAmenityCollage = ({
  homepageData,
  layout,
}: AvailableHomePageData): TConditionalRender<SingleAmenityCollageProps> => {
  const brandCode = homepageData?.brandCode ?? '';
  const spaNavLink = findNavLink(HotelPage.SPA, layout?.headerData?.mainNavLinks)?.url;
  const golfNavLink = findNavLink(HotelPage.GOLF, layout?.headerData?.mainNavLinks)?.url;

  if (!spaNavLink && golfNavLink && homepageData?.golf) {
    return createSingleAmenityCollage(
      brandCode,
      homepageData?.golf?.desc || '',
      'golf',
      homepageData.golf.imageRatios,
      golfNavLink
    );
  }

  if (!golfNavLink && spaNavLink && homepageData?.spa) {
    return createSingleAmenityCollage(
      brandCode,
      homepageData.spa.desc || '',
      'spa',
      homepageData.spa.imageRatios,
      spaNavLink
    );
  }
  return DO_NOT_RENDER;
};
