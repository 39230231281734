import { DO_NOT_RENDER, HotelPage } from '../../helpers/constants';

import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import { findNavLink } from '../../helpers/utils/server/findNavLink';
import { convertCmsImageRatiosToMap } from '../../helpers/utils/server/convertCmsImageRatiosToMap';
import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import { baseUrl } from '../../helpers/env-vars';

const mapCmsImageToMeetingsAndEventsImg = (
  img: NonNullable<NonNullable<GetHotelHomePageQuery['hotel']>['meetings']>['mainImage']
) => {
  if (!img) {
    return;
  }
  const { threeByTwo, twentyOneByNine } = convertCmsImageRatiosToMap(img.ratios);

  return {
    threeByTwo,
    twentyOneByNine,
    altText: img.altText || undefined,
  };
};

export const mapHomePageDataToMeetingsAndEvents = ({
  ctyhocn,
  homepageData,
  layout,
  showMeetingsSimplified,
}: AvailableHomePageData) => {
  const hasEventPage = Boolean(
    findNavLink(HotelPage.MEETINGSEVENTS, layout?.headerData?.mainNavLinks)?.url
  );
  const brandCode = layout.brandCode;
  const hasBaseUrlBrandCodeCtyhocn = baseUrl && brandCode && ctyhocn;
  const meetingsSimplified =
    showMeetingsSimplified && hasBaseUrlBrandCodeCtyhocn
      ? `${baseUrl}/en/group-search/?query=${ctyhocn}&brandCode=${brandCode}&numRooms=0&attendee_count=10`
      : '';

  return homepageData?.meetings?.desc && homepageData?.meetings?.headline
    ? {
        description: homepageData.meetings.desc,
        hasAdditionalLinks:
          !hasEventPage && (!!meetingsSimplified || !!homepageData.meetings.rfpUrl),
        hasEventPage,
        headline: homepageData.meetings.headline,
        image: mapCmsImageToMeetingsAndEventsImg(homepageData.meetings.mainImage),
        links: {
          hiltonLinkUrl: homepageData.meetings.hiltonLinkUrl,
          meetingsSimplified,
          rfpUrl: homepageData.meetings.rfpUrl,
          roomBlockUrl: hasBaseUrlBrandCodeCtyhocn
            ? `${baseUrl}/en/group-search/?query=${ctyhocn}&brandCode=${brandCode}&numRooms=10`
            : '',
        },
      }
    : DO_NOT_RENDER;
};
