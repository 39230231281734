import { DO_NOT_RENDER } from '../../helpers/constants';

import type { TCelebrityQuote } from './CelebrityQuote';
import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';

export const mapHomePageDataToCelebrityQuote = ({
  homepageData,
}: AvailableHomePageData): TConditionalRender<TCelebrityQuote> => {
  if (homepageData?.propertyComponents?.quote) {
    const { name_noTx, quote_noTx, shortBio_noTx } = homepageData?.propertyComponents?.quote;

    return {
      name: name_noTx,
      quote: quote_noTx,
      ...(shortBio_noTx ? { shortBio: shortBio_noTx } : {}),
    };
  }

  return DO_NOT_RENDER;
};
