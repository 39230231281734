import { getAspectRatioUrl } from '@dx-ui/utilities-images';
import { DO_NOT_RENDER } from '../../helpers/constants';

import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { THalfAndHalfCarouselProps } from './HalfAndHalfCarousel-types';

export const mapHomePageDataToHalfAndHalfCarousel = ({
  homepageData,
}: AvailableHomePageData): TConditionalRender<THalfAndHalfCarouselProps> => {
  const isPositionOdd = homepageData?.brandCode !== 'GU' && homepageData?.brandCode !== 'PY';

  const items = homepageData?.propertyComponents?.features?.map((feature) => {
    return {
      headlineLevel: 2,
      name: feature?.headline,
      desc: feature?.longDescription,
      image: {
        altText: feature?.images[0]?.altText,
        variants: [
          {
            size: 'md',
            url: getAspectRatioUrl({ src: feature?.images[0]?.ratios[0]?.url, width: 600 })
              ?.twoXimgUrl,
          },
        ],
        imageCaption: feature?.images[0]?.caption,
      },
      link: {
        label: feature?.links[0]?.linkLabel,
        url: feature?.links[0]?.linkUrl,
        isNewWindow: feature?.links[0]?.isNewWindow,
      },
    } as NonNullable<THalfAndHalfCarouselProps['items']>[number];
  });

  if (!items?.length) {
    return DO_NOT_RENDER;
  }

  return {
    items,
    isPositionOdd,
  };
};
