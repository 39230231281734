import type { HotelPage } from '../../constants';
import type { PropertyDrawerItem } from './getLayout.types';

export const findNavLink = (
  pageName: (typeof HotelPage)[keyof typeof HotelPage],
  navLinks: PropertyDrawerItem[] | undefined
) => {
  const matchingLink =
    navLinks?.find(
      ({ label, link }) => label?.toLowerCase() === pageName && link?.url.endsWith(`/${pageName}/`)
    )?.link || null;

  return matchingLink;
};
