import { DO_NOT_RENDER } from '../../helpers/constants';

import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { HonorsProps } from './honors';

const GU: HonorsProps['benefits'] = [
  {
    icon: 'price',
    title: 'price',
  },
  {
    icon: 'points',
    title: 'points',
  },
  {
    icon: 'star-burst',
    title: 'star-burst',
  },
  {
    icon: 'wifi',
    title: 'wifi',
  },
  {
    icon: 'water-bottle',
    title: 'water-bottle',
  },
] as const;

const LX: HonorsProps['benefits'] = [
  {
    icon: 'price',
    title: 'price',
  },
  {
    icon: 'wifi',
    title: 'wifi',
  },
  {
    icon: 'check-out',
    title: 'check-out',
  },
  {
    icon: 'water-bottle',
    title: 'water-bottle',
  },
  {
    icon: 'star-burst',
    title: 'star-burst',
  },
] as const;

const OU: HonorsProps['benefits'] = [
  {
    icon: 'price',
    title: 'price',
  },
  {
    icon: 'wifi',
    title: 'wifi',
  },
  {
    icon: 'check-out',
    title: 'check-out',
  },
  {
    icon: 'star-burst',
    title: 'star-burst',
  },
] as const;

const defaultBenefits: HonorsProps['benefits'] = [
  {
    icon: 'price',
    title: 'price',
  },
  {
    icon: 'points',
    title: 'points',
  },
  {
    icon: 'room-plan',
    title: 'room-plan',
  },
  {
    icon: 'app',
    title: 'app',
  },
  {
    icon: 'event',
    title: 'event',
  },
  {
    icon: 'digital-key',
    title: 'digital-key',
  },
] as const;

const handler: ProxyHandler<typeof definedBrandBenefits> = {
  get: (target: typeof definedBrandBenefits, key: string) =>
    target.hasOwnProperty(key) ? target[key as keyof typeof target] : defaultBenefits,
};

const definedBrandBenefits: { [brandCode: string]: HonorsProps['benefits'] } = {
  GU,
  LX,
  OU,
};

const honorsBenefitsProxy = new Proxy(definedBrandBenefits, handler);

export const mapHomePageDataToHonors = ({
  layout: { brandCode },
}: AvailableHomePageData): TConditionalRender<HonorsProps> => {
  if (!brandCode) {
    return DO_NOT_RENDER;
  }

  return {
    benefits: honorsBenefitsProxy[brandCode] || defaultBenefits,
  };
};
