import { createContext } from 'react';

import type { ReactNode } from 'react';
import type { THomePageComponents } from '../../../config/components';
import type { TComponentTheme } from '../../../config/themes/componentThemes';

type TComponentThemeProviderProps = {
  children: ReactNode;
  component: THomePageComponents | 'childComponent';
  theme: TComponentTheme<THomePageComponents>;
};

export const ComponentThemeProviderContext = createContext<
  Pick<TComponentThemeProviderProps, 'component' | 'theme'> | undefined
>(undefined);

export const ComponentThemeProvider = ({
  children,
  component,
  theme,
}: TComponentThemeProviderProps) => (
  <ComponentThemeProviderContext.Provider value={{ component, theme }}>
    <div className={theme.base} data-component-name={component}>
      {children}
    </div>
  </ComponentThemeProviderContext.Provider>
);

export const ChildComponentThemeProvider = ({
  children,
  theme,
}: Omit<TComponentThemeProviderProps, 'component'>) => (
  <ComponentThemeProviderContext.Provider value={{ theme, component: 'childComponent' }}>
    {children}
  </ComponentThemeProviderContext.Provider>
);
