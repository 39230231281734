import { DO_NOT_RENDER, HotelPage } from '../../helpers/constants';
import { convertCmsImageRatiosToMap } from '../../helpers/utils/server/convertCmsImageRatiosToMap';
import { findNavLink } from '../../helpers/utils/server/findNavLink';

import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import type { AmenitiesOverviewProps, AmenityOverviewData } from './AmenitiesOverview';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';

const mapCmsImageToAmenityImg = (
  images:
    | NonNullable<NonNullable<AvailableHomePageData['homepageData']>['golf']>['imageRatios']
    | NonNullable<NonNullable<AvailableHomePageData['homepageData']>['spa']>['imageRatios']
) => {
  if (!images) {
    return;
  }

  const [threeByTwoImage] = images;
  const { threeByTwo } = convertCmsImageRatiosToMap(threeByTwoImage?.ratios || []);

  return {
    imageAltText: threeByTwoImage?.altText || '',
    imageUrl: threeByTwo,
    ...(threeByTwoImage?.caption && {
      captionData: {
        caption: threeByTwoImage?.caption,
      },
    }),
  };
};

const transformAmenityData = (
  amenityData:
    | NonNullable<GetHotelHomePageQuery['hotel']>['golf']
    | NonNullable<GetHotelHomePageQuery['hotel']>['spa'],
  headline: 'spa' | 'golf',
  navLink: string
): AmenityOverviewData => {
  const imageData = mapCmsImageToAmenityImg(amenityData?.imageRatios || []);

  return {
    ctaUrl: navLink,
    description: amenityData?.desc || '',
    headline,
    image: imageData,
  };
};

export const mapHomePageDataToAmenitiesOverview = ({
  homepageData,
  layout,
}: AvailableHomePageData): TConditionalRender<AmenitiesOverviewProps> => {
  const spaNavLink = findNavLink(HotelPage.SPA, layout?.headerData?.mainNavLinks)?.url;
  const golfNavLink = findNavLink(HotelPage.GOLF, layout?.headerData?.mainNavLinks)?.url;

  if (spaNavLink && golfNavLink && homepageData?.spa && homepageData?.golf) {
    const spaAmenityData = transformAmenityData(homepageData.spa, 'spa', spaNavLink);
    const golfAmentityData = transformAmenityData(homepageData.golf, 'golf', golfNavLink);

    return { amenitiesOverviewData: [spaAmenityData, golfAmentityData] };
  }

  return DO_NOT_RENDER;
};
