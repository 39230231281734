import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import { DO_NOT_RENDER } from '../../helpers/constants';
import { slideData } from '../full-width-image-carousel/dataMappers';
import type { FullWidthDynamicHeroProps } from './full-width-dynamic-hero';
import { mapVideosToMultimediaList } from '../../helpers/utils/server/videoMappers';
import { mapMultimediaListToVideoProps } from '@dx-ui/osc-video-player';

export const mapHomePageDataToFullWidthDynamicHero = ({
  homepageData,
  layout,
}: AvailableHomePageData): TConditionalRender<FullWidthDynamicHeroProps> => {
  if (homepageData?.videos?.length) {
    const multimedia = mapVideosToMultimediaList(homepageData.videos);
    const brandCode = homepageData?.brandCode || '';
    const pathname = layout.homeUrlPath || '';
    const mappedPage = { brandCode, pathname };
    return {
      video: mapMultimediaListToVideoProps({ multimedia, mappedPage }),
    };
  }

  const imageSlidesData = slideData(homepageData?.images?.fullWidthImageCarousel);
  if (imageSlidesData) {
    return {
      carousel: {
        imageSlidesData,
      },
    };
  }

  return DO_NOT_RENDER;
};
