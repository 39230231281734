import type { VideoPlayerProps } from '@dx-ui/osc-video-player';
import { mapMultimediaListToVideoProps } from '@dx-ui/osc-video-player';
import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import { DO_NOT_RENDER } from '../../helpers/constants';
import { mapVideosToMultimediaList } from '../../helpers/utils/server/videoMappers';

export const mapHomePageDataToFullWidthVideo = ({
  homepageData,
  layout,
}: AvailableHomePageData): TConditionalRender<VideoPlayerProps> => {
  if (homepageData?.videos?.length) {
    const multimedia = mapVideosToMultimediaList(homepageData.videos);
    const brandCode = homepageData?.brandCode || '';
    const pathname = layout.homeUrlPath || '';
    const mappedPage = { brandCode, pathname };
    return mapMultimediaListToVideoProps({ multimedia, mappedPage });
  }

  return DO_NOT_RENDER;
};
