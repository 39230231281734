import { DO_NOT_RENDER } from '../../helpers/constants';

import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { PartnershipInfoProps } from './partnership-info';

export const mapHomePageDataToPartnershipInfo = ({
  homepageData,
}: AvailableHomePageData): TConditionalRender<PartnershipInfoProps> =>
  homepageData?.partnerships?.[0]
    ? {
        partnership: homepageData.partnerships[0],
      }
    : DO_NOT_RENDER;
