import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import { DO_NOT_RENDER } from '../../helpers/constants';
import type { TTab, TTabbedOverview } from './TabbedOverview';
import type { TTabbedOverviewContent } from './TabbedOverviewContent';

export type TRestaurant = NonNullable<
  NonNullable<AvailableHomePageData['homepageData']>['restaurantOverview']
>['restaurants'];

export const getOnSiteRestaurants = (restaurants: TRestaurant | undefined) =>
  restaurants?.filter((restaurant) => restaurant.onSite === true);

const getDiningTraits = (restaurants: TRestaurant | undefined) => {
  const onSiteRestaurants = getOnSiteRestaurants(restaurants);
  const traits = [];
  const hasDiningWithCTA =
    ((onSiteRestaurants?.length ?? 0) === 1 && onSiteRestaurants?.[0]?.diningMenus_noTx?.length) ||
    (onSiteRestaurants?.length ?? 0) > 1;

  if (onSiteRestaurants?.length) {
    const diningTrait = hasDiningWithCTA
      ? 'dining-component-type:dining-with-cta'
      : 'dining-component-type:dining-with-no-cta';
    traits.push(diningTrait, 'dining-component:has-onsite-dining');
  } else {
    traits.push('dining-component:no-onsite-dining');
  }

  return traits;
};

const mapSpaOrGolfForTab = (
  overview: NonNullable<GetHotelHomePageQuery['hotel']>['golf' | 'spa'],
  id: 'golf' | 'spa'
): TTabbedOverviewContent | null => {
  if (overview?.desc) return { id, image: overview?.images[0], description: overview.desc };

  return null;
};

const mapRestaurantDataForDiningTab = (
  restaurantOverview: NonNullable<GetHotelHomePageQuery['hotel']>['restaurantOverview'],
  onSiteRestaurants?: TTabbedOverview['onSiteRestaurants']
): TTab => {
  if (!onSiteRestaurants?.length) return null;

  if (onSiteRestaurants?.length === 1) {
    const onSiteOutlet = onSiteRestaurants[0];
    const hasDiningMenu = onSiteOutlet?.diningMenus_noTx?.length;
    return onSiteOutlet?.description
      ? {
          id: 'dining',
          restaurantName: onSiteOutlet?.name_noTx || undefined,
          image: onSiteOutlet?.halfAndHalfImage[0],
          description: onSiteOutlet.description,
          hoursOfOperation: hasDiningMenu ? null : onSiteOutlet?.hoursOfOperation,
        }
      : null;
  }

  return restaurantOverview?.desc
    ? {
        id: 'dining',
        image: restaurantOverview.image,
        description: restaurantOverview.desc,
      }
    : null;
};

export const mapHomePageDataToTabbedOverview = ({
  homepageData,
  showJapaneseDiningComponent,
}: AvailableHomePageData): TTabbedOverview => {
  const traits = getDiningTraits(homepageData?.restaurantOverview?.restaurants);
  const onSiteRestaurants = getOnSiteRestaurants(homepageData?.restaurantOverview?.restaurants);

  if (showJapaneseDiningComponent) {
    return {
      diningTab: null,
      golfTab: mapSpaOrGolfForTab(homepageData?.golf, 'golf'),
      spaTab: mapSpaOrGolfForTab(homepageData?.spa, 'spa'),
    };
  }

  return {
    golfTab: mapSpaOrGolfForTab(homepageData?.golf, 'golf'),
    onSiteRestaurants,
    diningTab: mapRestaurantDataForDiningTab(homepageData?.restaurantOverview, onSiteRestaurants),
    spaTab: mapSpaOrGolfForTab(homepageData?.spa, 'spa'),
    traits,
  };
};

export const mapHomePageDataToJapaneseDiningComponent = ({
  homepageData,
  showJapaneseDiningComponent,
}: AvailableHomePageData): TConditionalRender<TTabbedOverview> => {
  const onSiteRestaurants = getOnSiteRestaurants(homepageData?.restaurantOverview?.restaurants);

  const traits = getDiningTraits(homepageData?.restaurantOverview?.restaurants);

  if (onSiteRestaurants?.length && showJapaneseDiningComponent) {
    return {
      diningTab: mapRestaurantDataForDiningTab(homepageData?.restaurantOverview, onSiteRestaurants),
      golfTab: null,
      spaTab: null,
      onSiteRestaurants,
      traits,
    };
  }

  return DO_NOT_RENDER;
};
