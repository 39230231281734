import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import { DO_NOT_RENDER } from '../../helpers/constants';
import { convertCmsImageRatiosToMap } from '../../helpers/utils/server/convertCmsImageRatiosToMap';
import type { RestaurantWithMenu } from '../dining-buttons/DiningButtons';
import type { TStickySideBySideProps } from './StickySideBySide.types';

const mapCmsImageToStickyImageData = (
  img: NonNullable<
    NonNullable<NonNullable<GetHotelHomePageQuery>['hotel']>['restaurantOverview']
  >['images'][number]
) => {
  const { threeByTwo } = convertCmsImageRatiosToMap(img.ratios);
  return {
    url: threeByTwo || '',
    ...(img.caption && {
      caption: img.caption,
    }),
    ...(img.altText && {
      alt: img.altText,
    }),
  };
};

export const mapHomePageDataToStickySideBySide = ({
  homepageData,
}: AvailableHomePageData): TConditionalRender<TStickySideBySideProps> => {
  if (homepageData?.restaurantOverview) {
    const stickyImagesData = homepageData?.restaurantOverview?.images
      .slice(0, 4)
      .filter((img) => img.url)
      .map((img) => mapCmsImageToStickyImageData(img));

    if (stickyImagesData?.length > 1) {
      const onSiteRestaurants = homepageData?.restaurantOverview?.restaurants.reduce(
        (accumulatedRestaurants = [], currentRestaurant) => {
          if (currentRestaurant.onSite === true) {
            return [
              ...accumulatedRestaurants,
              {
                name_noTx: currentRestaurant.name_noTx,
                diningMenus_noTx: currentRestaurant.diningMenus_noTx,
                link: currentRestaurant.link,
              },
            ];
          }

          return accumulatedRestaurants;
        },
        [] as Array<RestaurantWithMenu>
      );

      return {
        headline: homepageData?.restaurantOverview?.headline || '',
        description: homepageData?.restaurantOverview?.desc || '',
        images: stickyImagesData,
        onSiteRestaurants,
      };
    }
  }
  return DO_NOT_RENDER;
};
