import { DO_NOT_RENDER } from '../../helpers/constants';

import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { EventsInfoProps } from './events-info';

export const mapHomePageDataToEventInfo = ({
  ctyhocn,
  homepageData,
  layout,
  showMeetingsSimplified,
}: AvailableHomePageData): TConditionalRender<EventsInfoProps> => {
  const hideGroupsBlock = Boolean(homepageData?.hideGroupsBlock);

  if (hideGroupsBlock) {
    return DO_NOT_RENDER;
  }

  const eventsLink = layout?.headerData?.mainNavLinks?.find((mainNavLink) =>
    mainNavLink?.link?.url?.endsWith('/events/')
  )?.link?.url;

  return {
    brandCode: homepageData?.brandCode,
    ctyhocn,
    eventsLink,
    meetings: homepageData?.meetings,
    showMeetingsSimplified,
  };
};
