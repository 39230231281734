import { availableComponents, componentDataMappers } from './homePageComponents';
import { ComponentThemeProvider } from '../providers/theme/theme-provider';
import { DO_NOT_RENDER } from '../constants';

import type {
  AvailableHomePageData,
  MappedComponents,
} from '../componentMapping/homePageComponents';
import type { THomePageComponents } from '../../config/components';
import type { TAppConfig } from '../../config/types';
import type { TComponentTheme } from '../../config/themes/componentThemes';

export type TComponentRenderObject = {
  componentName: THomePageComponents;
  props: MappedComponents;
  theme: TComponentTheme<THomePageComponents>;
};

export type TConditionalRender<T> = ({ [P in keyof T]?: T[P] } & typeof DO_NOT_RENDER) | T;

const serializeJson = (data: object) => JSON.parse(JSON.stringify(data));

/**
 * This function creates an array of objects by mapping `components`
 * to their associated data mapper. If `components` is empty, the
 * default component order will be used.
 *
 * The returned mappedComponents array will be used by the `renderComponents`
 * function to render components on the page with any custom wrapper class
 * name that is defined in `customTheme`.
 *
 * Future state will take in page name to make this function more dynamic.
 *
 * Important: When building data mappers, make sure to only map data that is
 * required for the component to render/function.
 *
 * Mapping should happen server-side, so the less data we need to send via
 * `getServerSideProps`, the better.
 *
 * @param components array of Home page components to be rendered.
 * @param data all data required to successfully render the Home page components.
 * @returns Array of {@linkcode TComponentRenderObject | TComponentRenderObjects} to render on the client.
 */
export const mapHomePageDataToComponentProps = (
  components: TAppConfig['pageConfig']['home'],
  data: AvailableHomePageData
): TComponentRenderObject[] =>
  serializeJson(
    components.flatMap(({ component, theme }) => {
      const renderData = componentDataMappers[component](data);

      if (renderData === DO_NOT_RENDER) {
        return [];
      }

      return {
        componentName: component,
        props: {
          ...renderData,
        },
        theme,
      };
    })
  );

/**
 * This function generates an array of JSX.Elements to be rendered on the
 * page by iterating over an array of component configuration objects,
 * mapping each configuration to its corresponding React component.
 *
 * Each component rendered by this function is wrapped with a
 * {@linkcode ComponentThemeProvider} to ensure it has access to its respective
 * theme.
 *
 * @returns an array of JSX.Elements ready to be rendered.
 */
export const renderComponents = (mappedComponents: TComponentRenderObject[]): React.JSX.Element[] =>
  mappedComponents.map(({ componentName, props, theme }) => {
    const Component = availableComponents[componentName] as React.FC;

    return (
      <ComponentThemeProvider component={componentName} theme={theme} key={componentName}>
        <Component {...props} key={componentName} />
      </ComponentThemeProvider>
    );
  });
