import { DO_NOT_RENDER } from '../../helpers/constants';
import { convertCmsImageRatiosToMap } from '../../helpers/utils/server/convertCmsImageRatiosToMap';

import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';
import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { FullWidthHeroImageCarouselProps } from './full-width-image-carousel';
import type { HotelImage } from '@dx-ui/gql-types';

type SlideImage = Omit<HotelImage, 'variants'>;

const mapCmsImageToCarouselImage = (
  imgSlide: NonNullable<
    NonNullable<NonNullable<GetHotelHomePageQuery>['hotel']>['images']
  >['fullWidthImageCarousel'][number]
) => {
  const { twentyOneByNine, threeByTwo } = convertCmsImageRatiosToMap(imgSlide.ratios);
  return {
    ...(twentyOneByNine && {
      desktopImgUrl: twentyOneByNine ?? '',
    }),
    ...(threeByTwo && {
      mobileImgUrl: threeByTwo ?? '',
    }),
    altText: imgSlide.altText ?? undefined,
  };
};

export const slideData = (carouselContent: Array<SlideImage> | undefined) => {
  if (carouselContent?.length) {
    return carouselContent
      .slice(0, 12)
      .filter((img) => img.url)
      .map((img) => mapCmsImageToCarouselImage(img));
  }

  return null;
};

export const mapHomePageDataToFullWidthCarousel = ({
  homepageData,
}: AvailableHomePageData): TConditionalRender<FullWidthHeroImageCarouselProps> => {
  const imageSlidesData = slideData(homepageData?.images?.fullWidthImageCarousel);

  if (imageSlidesData) {
    return {
      imageSlidesData,
    };
  }

  return DO_NOT_RENDER;
};
