import { DO_NOT_RENDER } from '../../helpers/constants';

import type { TConditionalRender } from '../../helpers/componentMapping/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { VoucherInfoProps } from './VoucherInfo';

const brandsWithDoubleVerticalBorder = ['GU'];

const shouldShowDoubleVerticalBorder = (brandCode: string) =>
  brandsWithDoubleVerticalBorder.includes(brandCode);

export const mapHomePageDataToVoucherInfo = ({
  homepageData,
}: AvailableHomePageData): TConditionalRender<VoucherInfoProps> => {
  const brandCode = homepageData?.brandCode ?? '';

  return homepageData?.facilityOverview?.voucherUrl
    ? {
        showDoubleVerticalBorder: shouldShowDoubleVerticalBorder(brandCode),
        hotelName: homepageData?.name,
        voucherUrl: homepageData.facilityOverview.voucherUrl,
      }
    : DO_NOT_RENDER;
};
